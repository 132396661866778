export default class Logger {
    readonly tag: string
    private readonly makeMessage: (className: string, method: string, message: string) => string

    constructor(tag?: string) {
        this.tag = tag ?? ""
        if (tag) {
            this.makeMessage = makeMessageClassMethod
        } else {
            this.makeMessage = makeMessageMethod
        }
    }

    functionLogger(functionName?: string): FunctionLogger {
        return new FunctionLogger(this, functionName)
    }

    d(method: string, message: string) {
        console.debug(this.makeMessage(this.tag, method, message))
    }

    i(method: string, message: string) {
        console.info(this.makeMessage(this.tag, method, message))
    }

    w(method: string, message: string) {
        console.warn(this.makeMessage(this.tag, method, message))
    }

    // message can also be of Error type
    e(method: string, message: string | Error | any) {
        console.error(this.makeMessage(this.tag, method, message))
    }

    static e(tag: string, method: string, message: string | Error | any) {
        console.error(makeMessageClassMethod(tag, method, message))
    }
}

export class FunctionLogger {
    readonly logger: Logger
    readonly functionName: string

    constructor(logger: Logger, functionName?: string) {
        this.logger = logger
        this.functionName = functionName ?? ""
    }

    d = (message: string) => this.logger.d(this.functionName, message)

    i = (message: string) => this.logger.i(this.functionName, message)

    w = (message: string) => this.logger.w(this.functionName, message)

    // @param [message] - string message. But Error can be also passed directly, without stringification.
    e = (message: string) => this.logger.e(this.functionName, message)
}

function makeMessageClassMethod(className: string, method: string, message: string) {
    return `${className}.${method}() ${message}`
}

function makeMessageMethod(_: string, method: string, message: string) {
    if (method.length) {
        return `${method}() ${message}`
    } else {
        return message
    }
}
