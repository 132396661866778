import { Box, Card } from "@mui/material"
import { FlexRow } from "../Styles/Styles"
import { Label5Gray, LabelBlack4 } from "../Styles/Labels"
import { Space } from "../Styles/Spacer"
import { GqlBookingContainerImageFragment, GqlBookingContainerPreviewFragment, GqlBookingFragment } from "../../../generated/graphql/types"
import React from "react"
import { ContainerCardContent, ContainerCardDetailIcon, StyledCardActionArea } from "../Styles/ContainerCard"
import { Carousel } from "react-bootstrap"
import { ContainerImage } from "./ContainerMedia"

export function ContainerCard({ data, onClick }: ContainerCardArgs) {
    return (
        <Card sx={{ boxShadow: 0 }}>
            <StyledCardActionArea disableRipple onClick={() => onClick(data.id)}>
                <Box>
                    <ContainerImagesCarousel images={data.images} />
                    <ContainerCardCommodityTypeOverlay />
                </Box>
                <ContainerCardContent>
                    <FlexRow>
                        <LabelBlack4>{data.shippingContainerNumber}</LabelBlack4>
                        <Space />
                        <LabelBlack4>{data.stock?.mass ?? 0}mt</LabelBlack4>
                    </FlexRow>
                    <FlexRow>
                        <Label5Gray>{data.commodityCategoryDisplayString}</Label5Gray>
                        <Interpunct />
                        <Label5Gray>{data.booking?.externalBookingNumber}</Label5Gray>
                        <Interpunct />
                        <Label5Gray>{data.booking?.steamshipLine}</Label5Gray>
                    </FlexRow>
                    <Label5Gray>{portsData(data.booking)}</Label5Gray>
                </ContainerCardContent>
            </StyledCardActionArea>
        </Card>
    )
}

function ContainerImagesCarousel({ images }: { images?: GqlBookingContainerImageFragment[] }) {
    if (!images) {
        return <ContainerImage />
    }

    return (
        <Carousel data-interval="false">
            {images.map((image, index) => (
                // Simplest way to disable auto-slide. 5 is a magic number - without it the interval equals 0 somehow.
                <Carousel.Item key={index} interval={Number.MAX_SAFE_INTEGER / 5}>
                    <ContainerImage image={image} />
                </Carousel.Item>
            ))}
        </Carousel>
    )
}

const Interpunct = () => <Label5Gray style={{ marginLeft: "0.25rem", marginRight: "0.25rem" }}>·</Label5Gray>

const ContainerCardCommodityTypeOverlay = () => <ContainerCardDetailIcon> 🪵 Sawlogs </ContainerCardDetailIcon>

function portsData(booking?: GqlBookingFragment): string {
    const origin = booking?.originPort
    const destination = booking?.destinationPort
    if (!origin || !destination) return ""
    return `${origin.name}, ${origin.countryCode} > ${destination.name}, ${destination.countryCode}`
}

export interface ContainerCardArgs {
    data: GqlBookingContainerPreviewFragment
    onClick: (bookingContainerId: string) => void
}
