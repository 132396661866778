import { SimpleBasePage } from "./BasePage/SimpleBasePage"
import { Label3 } from "./Styles/Labels"
import { IconButton } from "@mui/material"
import { SignInLogoImage } from "./Styles/Navbar"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../Components/Router/AppRoutes"
import { FlexColumnCenter } from "./Styles/Styles"
import React from "react"

export const ContactSalesPage = () => (
    <SimpleBasePage>
        <ContactSalesView />
    </SimpleBasePage>
)

function ContactSalesView() {
    const navigate = useNavigate()
    return (
        <FlexColumnCenter>
            <IconButton disableRipple onClick={() => navigate(RoutePath.home)}>
                <SignInLogoImage src="navbar_logo_light.png" alt="TimberEye Logo" />
            </IconButton>
            <Label3>Reach out to our team with any questions you have.</Label3>
            <Label3>+1-310-612-5532</Label3>
            <Label3>support@timbereye.com</Label3>
        </FlexColumnCenter>
    )
}
