// import {User} from "../../CommonRef/Firebase/GeneratedDataModels/Firestore"
// import Logger from "../../Utils/Logger"
// import {firestore} from "../../Firebase/TimbereyeFirebase"
// import {Timestamp} from "firebase/firestore"
// import {createUserWithEmailAndPassword, fetchSignInMethodsForEmail, getAuth, signInWithEmailAndPassword} from "firebase/auth"

// const log = new Logger("AuthenticationRepository")

export default class AuthenticationRepository {
    // signInWithEmailAndPassword(email: string, password: string) {
    //     fetchSignInMethodsForEmail(getAuth(), email).then((signInMethods) => {
    //         if (signInMethods.length > 0) {
    //             signInWithEmailAndPassword(getAuth(), email, password).then((_) => {
    //                 log.d("signInWithEmailAndPassword", `User [${email}] exists`)
    //             })
    //         } else {
    //             createUserWithEmailAndPassword(getAuth(), email, password).then((_) => {
    //                 log.d("signInWithEmailAndPassword", `User [${email}] created`)
    //             })
    //         }
    //     })
    // }
    //
    // async getOrCreateUser(uid: string, email: string): Promise<User> {
    //     try {
    //         const user = await firestore.user.getUser(uid)
    //         if (user) {
    //             return user
    //         }
    //     } catch (error) {
    //         log.e("getOrCreateUser", error)
    //     }
    //
    //     return await this.createUserFromCurrentUser(uid, email)
    // }
    //
    // private async createUserFromCurrentUser(uid: string, email: string): Promise<User> {
    //     const user: User = {
    //         id: uid,
    //         email: email,
    //         created_time: Timestamp.now(),
    //     }
    //
    //     await firestore.user.createUser(user)
    //
    //     return user
    // }
}
