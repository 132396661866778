import Authentication from "./Authentication/Authentication"
import { firebase, TimbereyeFirebase } from "../Firebase/TimbereyeFirebase"
import TimberEyeComponent from "./TimberEye/TimberEyeComponent"

class Components {
    readonly firebase: TimbereyeFirebase = firebase
    readonly timbereye = new TimberEyeComponent()
    readonly auth = new Authentication()

    constructor() {
        console.log("Components")
    }
}

const components = new Components()

export { components }
