import { styled } from "styled-components"

export const ZStack = styled.div`
    width: 100%;
    display: grid;
`

export const ZStackDiv = styled.div`
    grid-row: 1;
    grid-column: 1;
`

export const ZStackDivOverlay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row: 1;
    grid-column: 1;
    z-index: 100; // We must overlay the bootstrap Carousel buttons too
`
