import { styled } from "styled-components"
import Device from "./Device"

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`

export const FullscreenFlexRow = styled(FlexRow)`
    height: 80vh;
    align-items: center;
`

export const FlexRowMatchParent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    width: 100%;
`

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`

export const FlexColumnCenter = styled(FlexColumn)`
    align-items: center;
    gap: 15px;
`

export const FlexDiv = styled.div`
    display: flex;
`

//
// Base Page
//

export const DashboardContainer = styled.div`
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
`

export const BodyContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 2.5rem;
    // width: 1150px !important;
    width: 100%;
    min-width: 500px !important;
    max-width: 25500px !important;
`

//
// Simple Base Page
//

const HeaderHeight = "10.5vh"
const FooterHeight = "10.5vh"
const ContentWidth = Device.mediaMulti({ xs: "width: 90vw", md: "width: 500px", lg: "width: 550px" })

export const SimplePageContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

// Body

export const SimpleBodyContainer = styled.div`
    position: absolute;
    top: ${HeaderHeight};
    bottom: ${FooterHeight};
    ${ContentWidth};
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const SimpleFooterContainer = styled.div`
    position: absolute;
    ${ContentWidth};
    height: ${FooterHeight};
    bottom: 0;
    display: flex;
    justify-content: space-between;
`
