import { NavbarView } from "../Navbar/NavbarView"
import { BodyContainer, DashboardContainer, FlexRow } from "../Styles/Styles"
import { SidebarView } from "../Navbar/SidebarView"
import React from "react"
import { useSnapshot } from "valtio"
import store from "../../Store/Store"

export function BasePage({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
    const state = useSnapshot(store.timbereye)

    return (
        <DashboardContainer>
            <NavbarView />
            <FlexRow>
                <SidebarView />
                <BodyContainer
                    style={{
                        marginLeft: state.sidebarCollapsed ? "85px" : "250px",
                        marginTop: "55px",
                    }}
                >
                    {children}
                </BodyContainer>
            </FlexRow>
        </DashboardContainer>
    )
}
