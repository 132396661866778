// import {CommodityGroup, CommodityGroupType, OrderStatus} from "../../CommonRef/Firebase/GeneratedDataModels/Firestore";
import OrderViewModel from "./OrderViewModel"
import ArrayUtils from "../../CommonRef/utils/ArrayUtils"
import Logger from "../../Utils/Logger"

const log = new Logger("OrdersViewModel")

export default class OrdersViewModel {
    // readonly orders: CommodityGroup[]
    readonly pending: OrderViewModel[] = []
    readonly active: OrderViewModel[] = []
    readonly complete: OrderViewModel[] = []
    readonly cancelled: OrderViewModel[] = []

    // constructor(groups: CommodityGroup[], isBuyer: boolean, businessId: string) {
    //     const orders = groups.filter((group) => group.type == CommodityGroupType.order)
    //     if (isBuyer) {
    //         this.orders = orders.filter((order) => order.details.order_details?.buyer_id === businessId)
    //     } else {
    //         this.orders = orders.filter((order) => order.details.order_details?.supplier_id === businessId)
    //     }
    //     const bookings = groups.filter((group) => group.type == CommodityGroupType.booking)
    //     const containers = groups.filter((group) => group.type == CommodityGroupType.container)
    //     const models = orders.map((order) => new OrderViewModel(order, bookings, containers))
    //     log.d("constructor", `orders: ${orders.length}, bookings: ${bookings.length}, containers: ${containers.length}`)
    //
    //     // Filter orders by pending, active, complete, cancelled
    //     this.pending = models.filter((model) => ArrayUtils.isEmpty(model.orderBookings) && model.group.details.order_details?.status === OrderStatus.active)
    //     this.active = models.filter((model) => ArrayUtils.isNotEmpty(model.orderBookings) && model.group.details.order_details?.status === OrderStatus.active)
    //     this.complete = models.filter((model) => model.group.details.order_details?.status === OrderStatus.complete)
    //     this.cancelled = models.filter((model) => model.group.details.order_details?.status === OrderStatus.cancelled)
    //
    //     // Set the order list section title using the first member of each group
    //     assignTitle(this.pending, "Pending")
    //     assignTitle(this.active, "Active")
    //     assignTitle(this.complete, "Complete")
    //     assignTitle(this.cancelled, "Cancelled")
    // }
}

function assignTitle(viewModels: OrderViewModel[], value: string) {
    const item = viewModels[0]
    if (item) {
        item.sectionTitle = "Pending"
    }
}
