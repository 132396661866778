import React from "react"
import { Grid } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { FlexRowMatchParent } from "../Styles/Styles"
import { ContainerFeedSelector, ContainerFeedSelectorProps } from "./ContainerFeedSelector"
import { SteamshipLine, useFormOptionsQuery } from "../../../generated/graphql/types"
import Logger from "../../Utils/Logger"
import { SearchButton } from "../Styles/Buttons"
import { StyledSearchBar } from "../Styles/SearchBar"

const log = new Logger("ContainerFeedSearchBarView")

export function ContainerFeedSearchBarView({ onSearch }: { onSearch: (options: ContainerFeedFilters) => void }) {
    const { loading, error, data } = useFormOptionsQuery()

    const filters = React.useRef<ContainerFeedFilters>({})

    if (error) {
        log.e("useFormOptionsQuery", error)
    }

    const steamshipLines = data?.formOptions?.steamshipLines || []
    const ports = data?.formOptions?.ports || []
    const commodityCategories = data?.formOptions?.commodityCategories || []

    function search() {
        onSearch(filters.current)
    }

    return (
        <FlexRowMatchParent>
            <StyledSearchBar>
                <Grid spacing={0} container>
                    <ContainerFeedSelectorGridItem
                        label="Steamship Line"
                        placeholder="Select a carrier"
                        options={steamshipLines}
                        onChange={(ids) => {
                            filters.current.steamshipLines = ids as SteamshipLine[]
                            search()
                        }}
                    />
                    <ContainerFeedSelectorGridItem
                        label="Origin"
                        placeholder="Add Origin Port"
                        options={ports}
                        onChange={(ids) => {
                            filters.current.originPortIds = ids
                            search()
                        }}
                    />
                    <ContainerFeedSelectorGridItem
                        label="Destination"
                        placeholder="Add Destination Port"
                        options={ports}
                        onChange={(ids) => {
                            filters.current.dischargePortIds = ids
                            search()
                        }}
                    />
                    <ContainerFeedSelectorGridItem
                        label="Commodity"
                        placeholder="Add Commodity"
                        options={commodityCategories}
                        onChange={(ids) => {
                            // filters.current.commodityCategory = ids?.at(0)
                            // search()
                        }}
                    />
                </Grid>
                <SearchButton onClick={search}>
                    <SearchIcon />
                </SearchButton>
            </StyledSearchBar>
        </FlexRowMatchParent>
    )
}

const ContainerFeedSelectorGridItem = (props: ContainerFeedSelectorProps) => (
    <Grid item xs={3}>
        <ContainerFeedSelector {...props} />
    </Grid>
)

export interface ContainerFeedFilters {
    commodityCategory?: string
    dischargePortIds?: string[]
    originPortIds?: string[]
    steamshipLines?: SteamshipLine[]
}
