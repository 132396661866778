import { styled } from "styled-components"
import { LabelBlack1 } from "./Labels"
import { Button } from "@mui/material"

// Form

export const AuthTitle = styled(LabelBlack1)`
    margin: 0;
    font-size: 55px;
`

export const AuthMagicLinkButton = styled(Button)`
    width: 100%;
    padding: 0.75rem;
`
