import { Link } from "react-router-dom"
import { Menu } from "react-pro-sidebar"
import { useSnapshot } from "valtio"
import store from "../../Store/Store"
import Divider from "@mui/material/Divider"
import { RoutePath } from "../../Components/Router/AppRoutes"
import { SpacerV10, SpacerV64 } from "../Styles/Spacer"

// Icons from https://mui.com/material-ui/material-icons/
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined"
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined"
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined"
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined"
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined"
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined"
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined"
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined"
import { ReactNode } from "react"
import { SidebarMenuItem, SidebarNav } from "../Styles/Sidebar"
import { Color } from "../Styles/Generic"

export function SidebarView() {
    const state = useSnapshot(store.timbereye)

    return (
        <SidebarNav collapsed={state.sidebarCollapsed} backgroundColor={"white"}>
            <Menu
                rootStyles={{ marginLeft: 10, marginRight: 10, marginTop: 75 }}
                menuItemStyles={{
                    button: ({ level, active }) => {
                        // Only apply styles on first level elements of the tree
                        if (level === 0) {
                            return {
                                backgroundColor: active ? Color.LightGray : undefined,
                                borderRadius: 8,
                                height: 40,
                                paddingLeft: 12,
                                fontWeight: active ? 700 : 400,
                                // Hover override: https://stackoverflow.com/a/75142167/5035991
                                "&:hover": {
                                    backgroundColor: `${Color.LightGray} !important`,
                                    // color: "white !important",
                                    // borderRadius: "8px !important",
                                    // fontWeight: "bold !important",
                                },
                            }
                        }
                    },
                }}
            >
                <LinkMenuItem icon={<VisibilityOutlinedIcon />} text="Explore" route={RoutePath.containerFeed} />
                <LinkMenuItem icon={<AccountBoxOutlinedIcon />} text="My Profile" route={RoutePath.marketingProfile} />
                <LinkMenuItem icon={<PeopleAltOutlinedIcon />} text="People" route={RoutePath.marketingPeople} />
                <SidebarDivider />
                <LinkMenuItem icon={<AssignmentOutlinedIcon />} text="Orders" route={RoutePath.marketingOrders} />
                <LinkMenuItem icon={<DirectionsBoatFilledOutlinedIcon />} text="Bookings" route={RoutePath.marketingBookings} />
                <LinkMenuItem icon={<LocalShippingOutlinedIcon />} text="Containers" route={RoutePath.marketingContainers} />
                <LinkMenuItem icon={<ArticleOutlinedIcon />} text="Documents" route={RoutePath.marketingDocuments} />
                <LinkMenuItem icon={<NotificationsNoneOutlinedIcon />} text="Notifications" route={RoutePath.marketingNotifications} />
                <SidebarDividerLarge />
                <LinkMenuItem icon={<AssuredWorkloadOutlinedIcon />} text="Capital" route={RoutePath.marketingCapital} />
                <LinkMenuItem icon={<BusinessOutlinedIcon />} text="Business" route={RoutePath.marketingBusiness} />
                <SidebarDivider />
                <LinkMenuItem icon={<ContactSupportOutlinedIcon />} text="Help" route={RoutePath.marketingHelp} />
            </Menu>
        </SidebarNav>
    )
}

export function LinkMenuItem({ icon, text, route }: { icon: ReactNode; text: string; route?: RoutePath }) {
    if (route === undefined) {
        return (
            <>
                <SpacerV10 />
                <SidebarMenuItem icon={icon}>{text}</SidebarMenuItem>
                <SpacerV10 />
            </>
        )
    }

    return (
        <>
            <SpacerV10 />
            <SidebarMenuItem icon={icon} component={<Link to={route} />} active={window.location.pathname === route}>
                {text}
            </SidebarMenuItem>
            <SpacerV10 />
        </>
    )
}

const SidebarDivider = () => (
    <>
        <SpacerV10 />
        <Divider />
    </>
)

const SidebarDividerLarge = () => (
    <>
        <SpacerV64 />
        <Divider />
    </>
)
