import React from "react"
import { BasePage } from "../BasePage/BasePage"
import { ContainerFeedFilters, ContainerFeedSearchBarView } from "./ContainerFeedSearchBarView"
import { LabelBlack1, LabelBlackBlueColor1 } from "../Styles/Labels"
import { FlexRowMatchParent } from "../Styles/Styles"
import { ContainerFeedView } from "./ContainerFeedView"

export function ContainerFeedPage() {
    const [filters, setFilters] = React.useState<ContainerFeedFilters>({})

    return (
        <BasePage>
            <FlexRowMatchParent>
                <LabelBlack1 style={{ marginBottom: 25 }}>
                    Buy <span><LabelBlackBlueColor1>AI powered</LabelBlackBlueColor1></span> commodity containers, <br />
                    shipped to any port in the world.
                </LabelBlack1>
            </FlexRowMatchParent>
            <ContainerFeedSearchBarView
                onSearch={(newFilters) => {
                    setFilters(structuredClone(newFilters))
                }}
            />
            <ContainerFeedView filters={filters} />
        </BasePage>
    )
}
