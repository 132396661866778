import { styled } from "styled-components"
import { MenuItem, Sidebar } from "react-pro-sidebar"
import { Color, Font, FontSize } from "./Generic"

export const SidebarNav = styled(Sidebar)`
    border-right: 1px ${Color.LightGray} solid !important;
    position: fixed !important;
    padding-bottom: 100px;
`

export const SidebarMenuItem = styled(MenuItem)`
    font-family: ${Font.Regular};
    font-size: ${FontSize._5};
    height: 30px !important;
`
