export class PersistentStorage {
    static get lastSignInBusinessId() {
        return localStorage.getItem("lastSignInBusinessId")
    }

    static set lastSignInBusinessId(businessId: string | null) {
        businessId ? localStorage.setItem("lastSignInBusinessId", businessId) : localStorage.removeItem("lastSignInBusinessId")
    }

    static get dashboardPerspective() {
        return localStorage.getItem("dashboardPerspective")
    }

    static set dashboardPerspective(perspective: string | null) {
        perspective ? localStorage.setItem("dashboardPerspective", perspective) : localStorage.removeItem("dashboardPerspective")
    }
}
