export default class StringUtils {
    static isEmpty(str: string) {
        return str.length === 0
    }

    static isNotEmpty(str: string) {
        return str.length !== 0
    }

    static isNullOrEmpty(str: string | undefined | null): boolean {
        return str ? str.length === 0 : true
    }

    static isNotNullOrEmpty(str: string | undefined | null): boolean {
        return !StringUtils.isNullOrEmpty(str)
    }
}
