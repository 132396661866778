import { SimpleBasePage } from "./BasePage/SimpleBasePage"
import React, { useState } from "react"
import { constants } from "../Config/Constants"
import { AuthMagicLinkButton, AuthTitle } from "./Styles/AuthStyles"
import { Label3 } from "./Styles/Labels"
import { SpacerV32, SpacerV40 } from "./Styles/Spacer"
import { components } from "../Components/Components"
import GoogleIcon from "@mui/icons-material/Google"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "../Components/Router/AppRoutes"
import { SignInLogoImage } from "./Styles/Navbar"
import { Box, CircularProgress, IconButton, TextField } from "@mui/material"
import { FlexRowMatchParent } from "./Styles/Styles"
import Logger from "../Utils/Logger"

const log = new Logger("LogInForm")

export const LogInPage = () => (
    <SimpleBasePage>
        <LogInForm />
    </SimpleBasePage>
)

function LogInForm() {
    const navigate = useNavigate()

    const [signingIn, setSigningIn] = useState(false)

    // Default email for Google Docs access. Emulators only.
    const [email, setEmail] = useState("opentesting@timbereye.com")

    const onSubmit = (event: any) => {
        event.preventDefault()
        setSigningIn(true)

        if (!constants.useFirebaseEmulators) {
            // Production
            components.auth
                .signInWithGoogle()
                .then(() => {
                    setSigningIn(false)
                })
                .catch((error: any) => {
                    log.e("onSubmit", error)
                    setSigningIn(false)
                })
        } else {
            // Development (emulators)
            components.auth.signInEmulators(email)
        }
    }

    return (
        <Box component="form" display="flex" flexDirection="column" onSubmit={onSubmit}>
            <SpacerV40 />
            <IconButton disableRipple onClick={() => navigate(RoutePath.home)}>
                <SignInLogoImage src="navbar_logo_light.png" alt="TimberEye Logo" />
            </IconButton>
            <AuthTitle>Ok, let's log in</AuthTitle>
            <Label3>Track your orders with a TimberEye account.</Label3>
            {constants.useFirebaseEmulators ? <TextField type="email" placeholder={"Email"} value={email} onChange={(event: any) => setEmail(event.target.value)}></TextField> : <br />}
            <SpacerV40 />
            <AuthMagicLinkButton role={undefined} variant="outlined" tabIndex={-1} startIcon={<GoogleIcon />} size="large" type="submit" disabled={signingIn}>
                Continue With Google
            </AuthMagicLinkButton>
            <SpacerV32 />
            <FlexRowMatchParent>{signingIn ? <CircularProgress /> : <CircularProgress sx={{ visibility: "hidden" /* to occupy space but keep hidden */ }} />}</FlexRowMatchParent>
        </Box>
    )
}
