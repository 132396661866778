import { styled } from "styled-components"
import { AppBar } from "@mui/material"
import Device from "./Device"
import { Color } from "./Generic"

export const NavbarLogoImage = styled.img`
    ${Device.mediaMulti({ xs: "width: 130px", md: "width: 140px", lg: "width: 150px" })}
`

export const SignInLogoImage = styled.img`
    ${Device.mediaMulti({ xs: "width: 200px", md: "width: 200px", lg: "width: 200px" })}
`

export const AppBarNav = styled(AppBar)`
    border-bottom: 1px ${Color.LightGray} solid !important;
`
