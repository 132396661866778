import React, { useEffect } from "react"
import { RoutePath } from "../../Components/Router/AppRoutes"
import { useNavigate } from "react-router-dom"
import store from "../../Store/Store"
import { useSnapshot } from "valtio"
import { components } from "../../Components/Components"
import { Box, IconButton, Toolbar } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { AppBarNav, NavbarLogoImage } from "../Styles/Navbar"
import { OutlinedButton3 } from "../Styles/Buttons"
import { constants } from "../../Config/Constants"
import { AuthPresentationState } from "../../Store/AuthStore"

export const NavbarView = () => {
    const navigate = useNavigate()
    const authState = useSnapshot(store.auth)
    const timbereyeState = useSnapshot(store.timbereye)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1300 && !timbereyeState.sidebarCollapsed) {
                components.timbereye.collapseSidebar()
            }
        }

        window.addEventListener("resize", handleResize)
        window.addEventListener("load", handleResize)

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize)
            window.removeEventListener("load", handleResize)
        }
    }, [])

    return (
        <AppBarNav position="fixed" color="transparent" sx={{ boxShadow: 0, backgroundColor: "#fff" }}>
            <Toolbar>
                <IconButton size="large" edge="start" color="primary" sx={{ mr: 1.5 }} onClick={() => components.timbereye.toggleSidebar()}>
                    <MenuIcon />
                </IconButton>
                <IconButton edge="start" disableRipple onClick={() => navigate(RoutePath.home)}>
                    <NavbarLogoImage src="/navbar_logo_light.png" alt="" />
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                {/* <ButtonClear*/}
                {/*    onClick={() => {*/}
                {/*        navigate(RoutePath.tracker)*/}
                {/*    }}*/}
                {/* >*/}
                {/*    Track Containers*/}
                {/* </ButtonClear>*/}
                <OutlinedButton3
                    onClick={() => {
                        window.location.href = constants.iosAppAppstoreUrl
                    }}
                >
                    Get The App
                </OutlinedButton3>
                {authState.presentationState === AuthPresentationState.LoggedIn ? (
                    <OutlinedButton3
                        onClick={() => {
                            components.auth.signOut()
                        }}
                    >
                        Sign Out
                    </OutlinedButton3>
                ) : null}
            </Toolbar>
        </AppBarNav>
    )
}
