// import {CommodityGroup} from "../CommonRef/Firebase/GeneratedDataModels/Firestore";
import OrdersViewModel from "../ViewModel/Dashboard/OrdersViewModel"

export default class CommodityGroupsStore {
    // allGroups: CommodityGroup[] = []
    ordersViewModel: OrdersViewModel = new OrdersViewModel() // ([], true, "")
    // let orderId: string?
    // let order: CommodityGroup?
    // let bookingId: String?
    // let containerId: String?
    // let container: CommodityGroup?
}
