import React from "react"
import { SimpleNavbarView } from "../Navbar/SimpleNavbarView"
import { SimpleFooter } from "./SimpleFooter"
import { SimpleBodyContainer, SimplePageContainer } from "../Styles/Styles"

export function SimpleBasePage({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
    return (
        <SimplePageContainer>
            <SimpleNavbarView />
            <SimpleBodyContainer>{children}</SimpleBodyContainer>
            {/* <SimpleFooter />*/}
        </SimplePageContainer>
    )
}
