import Divider, { DividerProps } from "@mui/material/Divider"
import { PxRem } from "./Generic"
import { Box } from "@mui/material"

export const Spacer0 = () => Spacer({ space: PxRem._0 })

export const SpacerH4 = () => Spacer({ space: PxRem._4 })

export const SpacerH10 = () => Spacer({ space: PxRem._10 })

export const SpacerH32 = () => Spacer({ space: PxRem._32 })
export const SpacerH64 = () => Spacer({ space: PxRem._64 })
export const SpacerV5 = () => Spacer({ space: PxRem._5, orientation: "vertical" })
export const SpacerV10 = () => Spacer({ space: PxRem._10, orientation: "vertical" })

export const SpacerV14 = () => Spacer({ space: PxRem._14, orientation: "vertical" })

export const SpacerV16 = () => Spacer({ space: PxRem._16, orientation: "vertical" })

export const SpacerV24 = () => Spacer({ space: PxRem._24, orientation: "vertical" })

export const SpacerV32 = () => Spacer({ space: PxRem._32, orientation: "vertical" })

export const SpacerV40 = () => Spacer({ space: PxRem._40, orientation: "vertical" })

export const SpacerV64 = () => Spacer({ space: PxRem._64, orientation: "vertical" })

export const SpacerVPx = (props: { px: number }) => Spacer({ space: `${props.px}px`, orientation: "vertical" })

interface SpacerProps extends DividerProps {
    space?: string
}

function Spacer(props: SpacerProps) {
    const { space, sx, ...rest } = props
    const direction = rest?.orientation === "vertical" ? "borderBottomWidth" : "borderRightWidth" /* default */
    return <Divider flexItem sx={{ borderColor: "transparent", ...sx, ...{ [direction]: space ? space : "1px" /* default */ } }} {...rest} />
}

/**
 * Occupies all available space.
 */
export const Space = () => <Box sx={{ flexGrow: 1 }} />
