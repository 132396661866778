import { Box, ButtonBase } from "@mui/material"
import { Label3, LabelBlack2 } from "../Styles/Labels"
import { FlexColumn } from "../Styles/Styles"
import { constants } from "../../Config/Constants"

export function MobileHomePage() {
    return (
        <Box bgcolor="primary.main">
            <ButtonBase href={constants.iosAppAppstoreUrl}>
                <FlexColumn style={{ paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "1rem", paddingBottom: "1rem" }}>
                    <LabelBlack2 style={{ color: "white" }}>Welcome to TimberEye.</LabelBlack2>
                    <Label3 style={{ color: "white" }}>Tap here to download our iOS app.</Label3>
                </FlexColumn>
            </ButtonBase>
        </Box>
    )
}
