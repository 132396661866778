import { styled } from "styled-components"
import { FlexRowMatchParent } from "./Styles"
import { Color } from "./Generic"

export const StyledSearchBar = styled(FlexRowMatchParent)`
    border: 1px solid ${Color.LightGray};
    border-radius: 28px;
    box-shadow: 1px 2px 5px ${Color.LightGray};
    height: 65px;
    padding: 15px;
    margin-bottom: 35px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-right: 0;
`
