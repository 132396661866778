import { PersistentStorage } from "../Components/PersistentStorage"

export class TimberEyeStore {
    sidebarCollapsed = true

    get perspective() {
        return PersistentStorage.dashboardPerspective
    }
}

export enum MarketplacePerspective {
    buy = "buy",
    supply = "supply",
}
