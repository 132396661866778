import { RoutePath } from "../../Components/Router/AppRoutes"
// import { useNavigate } from "react-router-dom"
import { AppBar, Box, Toolbar } from "@mui/material"
// import { useTranslation } from "react-i18next"
import { Link5 } from "../Styles/Links"
import { SpacerH10 } from "../Styles/Spacer"

export const SimpleNavbarView = () => {
    // const { t } = useTranslation("auth")
    // const navigate = useNavigate()

    return (
        <AppBar position="static" color="transparent" elevation={0}>
            <Toolbar>
                <Box sx={{ flexGrow: 1 }} />
                {/* <Link5 route={RoutePath.home}>{t("back_home")}</Link5>*/}
                {/* <Link5 route={RoutePath.home}>{"Back Home"}</Link5>*/}
                <SpacerH10 />
            </Toolbar>
        </AppBar>
    )
}
