import { useEffect } from "react"
import "./App.css"
import { components } from "./Components/Components"
import Logger from "./Utils/Logger"
import { useSnapshot } from "valtio"
import store from "./Store/Store"
import { getAppRoutes } from "./Components/Router/AppRoutes"
import { useNavigate, useRoutes } from "react-router-dom"
import { constants } from "./Config/Constants"

const log = new Logger("App")

// TODO rm - debug var
let useEffectCount = 0

function App() {
    const authState = useSnapshot(store.auth)
    const navigate = useNavigate()
    const appRoutes = useRoutes(getAppRoutes())

    // Subscribe to authentication state changes
    // Note: This will render twice in debug mode, because we use StrictMode. In prod it will render only once. https://stackoverflow.com/a/60619061/5035991
    useEffect(() => {
        useEffectCount++
        let maxRenders = 1
        if (constants.useFirebaseEmulators) {
            maxRenders = 2
        }
        if (useEffectCount > maxRenders) {
            log.e("useEffect", `called ${useEffectCount} times`)
            return
        }
        components.auth.listenAuthStatus()
    }, [])

    // Redirect based on authentication state
    // useEffect(() => {
    //     log.d("authState changed", `${authState.presentationState}`)
    //     switch (authState.presentationState) {
    //         case AuthPresentationState.LoggedOut:
    //             // navigate(RoutePath.login)
    //             break
    //         case AuthPresentationState.LoggedIn:
    //             // TODO when in the login process, redirect to home; otherwise, stay on current page.
    //             // navigate(RoutePath.home)
    //             break
    //     }
    // }, [authState])

    return appRoutes
}

export default App
