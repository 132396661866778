import { styled } from "styled-components"
import { LabelBlack5 } from "./Labels"
import { CardActionArea, CardContent, CardMedia } from "@mui/material"

export const ContainerCardDetailIcon = styled(LabelBlack5)`
    position: absolute;
    top: 7px;
    left: 10px;
    background-color: white;
    border-radius: 14px;
    padding-left: 10px;
    padding-right: 11px;
    padding-top: 3px;
    padding-bottom: 3px;
`

export const ContainerCardMedia = styled(CardMedia)`
    min-height: 18vw;
    min-width: 18vw;
    height: 21vw;
    border-radius: 12px;
`

export const ContainerCardContent = styled(CardContent)`
    padding-top: 15px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
`

export const ContainerCardMediaTintOverlay = styled(ContainerCardMedia)`
    background-color: black;
    opacity: 0.1;
    position: absolute;
    width: 100%;
`

export const StyledCardActionArea = styled(CardActionArea)`
    .MuiCardActionArea-focusHighlight {
        background: transparent;
    }
`
