export default class Device {
    static readonly Size = {
        xs: "0px",
        md: "900px",
        lg: "1200px",
    }

    // min-width media queries, i.e. from given dimension and UP
    static readonly Up = {
        xs: `(min-width: ${Device.Size.xs})`,
        md: `(min-width: ${Device.Size.md})`,
        lg: `(min-width: ${Device.Size.lg})`,
    }

    static media(size: string, statement: string): string {
        return `@media ${size} { ${statement}; }`
    }

    static mediaMulti(props: { xs?: string; md?: string; lg?: string }): string {
        let result = ""
        if (props.xs) {
            result += Device.media(Device.Up.xs, props.xs)
        }
        if (props.md) {
            result += Device.media(Device.Up.md, props.md)
        }
        if (props.lg) {
            result += Device.media(Device.Up.lg, props.lg)
        }
        return result
    }
}
