import { styled } from "styled-components"
import { Color, Font, FontSize } from "./Generic"

//
// Regular
//

export const Label1 = styled.p`
    font-family: ${Font.Regular};
    font-size: ${FontSize._1};
    display: inline-block;
    color: black;
    margin-bottom: 0;
`

export const Label0 = styled(Label1)`
    font-size: ${FontSize._0};
`
export const Label2 = styled(Label1)`
    font-size: ${FontSize._2};
`
export const Label3 = styled(Label1)`
    font-size: ${FontSize._3};
`
export const Label4 = styled(Label1)`
    font-size: ${FontSize._4};
`
export const Label5 = styled(Label1)`
    font-size: ${FontSize._5};
`

export const Label5Gray = styled(Label1)`
    font-size: ${FontSize._5};
    color: #6a6a6a;
    font-weight: 500;
    margin-top: 6px;
`

export const Label6 = styled(Label1)`
    font-size: ${FontSize._6};
`

//
// Bold
//

export const LabelBold0 = styled(Label0)`
    font-family: ${Font.Black};
    -webkit-text-stroke: 1px black; /* width and color */
`
export const LabelBold1 = styled(Label1)`
    font-family: ${Font.Bold};
`
export const LabelBold2 = styled(Label2)`
    font-family: ${Font.Bold};
`
export const LabelBold3 = styled(Label3)`
    font-family: ${Font.Bold};
`
export const LabelBold4 = styled(Label4)`
    font-family: ${Font.Bold};
`
export const LabelBold5 = styled(Label5)`
    font-family: ${Font.Bold};
`

//
// Black
//

export const LabelBlack0 = styled(Label0)`
    font-family: ${Font.Black};
    -webkit-text-stroke: 0.5px black !important;
`
export const LabelBlack1 = styled(Label1)`
    font-family: ${Font.Black};
    -webkit-text-stroke: 1px black !important;
`
export const LabelBlackBlueColor1 = styled(Label1)`
    font-family: ${Font.Black};
    color: ${Color.Blue};
    -webkit-text-stroke: 1px ${Color.Blue} !important;
    //background:linear-gradient(rgba(250,0,0,0.5),transparent);
    //background-color:orange /*this your primary color*/
`
export const LabelBlack2 = styled(Label2)`
    font-family: ${Font.Black};
`
export const LabelBlack3 = styled(Label3)`
    font-family: ${Font.Black};
`
export const LabelBlack4 = styled(Label4)`
    font-family: ${Font.Black};
`
export const LabelBlack5 = styled(Label5)`
    font-family: ${Font.Black};
`
