import React, { HTMLAttributes, SyntheticEvent } from "react"
import { Autocomplete, Box, Checkbox, MenuItem, TextField } from "@mui/material"
import { Color } from "../Styles/Generic"
import { FlexColumn } from "../Styles/Styles"
import { Label6, LabelBlack5 } from "../Styles/Labels"

/**
 * https://muhimasri.com/blogs/mui-multiselect/#using-autocomplete-component
 */
export function ContainerFeedSelector({ label, placeholder, options, onChange }: ContainerFeedSelectorProps) {
    const [hasSelected, setHasSelected] = React.useState(false)

    const handleChange = (event: SyntheticEvent<Element, Event>, value: ContainerFeedSelectorOption | null) => {
        if (value !== null) {
            onChange([value.id])
            setHasSelected(true)
        } else {
            onChange(undefined)
            setHasSelected(false)
        }
    }

    return (
        <Autocomplete
            sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: `0px solid \`${Color.LightGray}\``,
                },
            }}
            componentsProps={{ popper: { style: { width: "fit-content" } } }}
            forcePopupIcon={false}
            disableCloseOnSelect
            options={options}
            noOptionsText="Loading options..."
            getOptionLabel={(option) => option.description}
            renderInput={(params) => (
                <TextField
                    {...params}
                    hiddenLabel
                    label={label}
                    margin={"dense"}
                    size={"small"}
                    placeholder={hasSelected ? undefined : placeholder}
                    InputLabelProps={{ shrink: true, style: { fontSize: 17, fontFamily: "Lato-Black", color: "#000" } }}
                    sx={{
                        "& input::placeholder": {
                            fontFamily: "Lato",
                            fontSize: "15px",
                        },
                        "& fieldset": { border: "none !important" },
                        border: "0px solid black",
                        marginTop: "22px",
                    }}
                />
            )}
            renderOption={(props, option, { selected }) => <ContainerFeedSelectorMenuItem props={props} option={option} selected={selected} />}
            onChange={handleChange}
        />
    )
}

export interface ContainerFeedSelectorProps {
    label: string
    placeholder: string
    options: ContainerFeedSelectorOption[]
    onChange: (ids: string[] | undefined) => void
}

interface ContainerFeedSelectorOption {
    id: string
    description: string
    detailDescription?: string
    url?: string
}

function ContainerFeedSelectorMenuItem({ props, option, selected }: { props: HTMLAttributes<HTMLLIElement>; option: ContainerFeedSelectorOption; selected: boolean }) {
    return (
        <MenuItem {...props} key={option.id} sx={{ width: "370px", mb: 1 }}>
            <Box
                component="img"
                sx={{
                    height: 55,
                    width: 55,
                    borderRadius: 2,
                    marginRight: 1,
                }}
                src={option.url ?? "/searchPlaceholder.png"}
            />
            <FlexColumn>
                <LabelBlack5>{option.detailDescription}</LabelBlack5>
                <Label6>{option.description}</Label6>
            </FlexColumn>
            <Checkbox checked={selected} sx={{ marginLeft: "auto" }} />
        </MenuItem>
    )
}
