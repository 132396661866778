// import {Business, BusinessMember, User} from "../CommonRef/Firebase/GeneratedDataModels/Firestore"

export enum AuthPresentationState {
    LoggedOut = "LoggedOut",
    LoggedIn = "LoggedIn",
}

export class AuthStore {
    userId: string | undefined = undefined // Only used for auth process
    // user?: User
    isGlobalAdmin = false
    // businessMembers: BusinessMember[] = [] // TODO check if assignment works
    // businessMember?: BusinessMember
    // businesses: Business[] = []
    // business?: Business
    presentationState = AuthPresentationState.LoggedOut

    reset() {
        this.userId = undefined
        // this.user = undefined
        this.isGlobalAdmin = false
        // this.businessMembers = []
        // this.businessMember = undefined
        // this.businesses = []
        // this.business = undefined
        this.presentationState = AuthPresentationState.LoggedOut
    }
}
